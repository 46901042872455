import { SimpleGrid, Icon, Stack, Flex, Container, Box } from '@chakra-ui/react';
import { FcBullish, FcOvertime, FcVoicePresentation } from 'react-icons/fc';

const Feature = ({ title, text, icon }) => {
    return (
        <Stack textAlign={"center"}>
            <Flex
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.100'}
                mb={1}
                mx={"auto"}
            >
                {icon}
            </Flex>
            <Box fontWeight={600}>{title}</Box>
            <Box color={'gray.600'}>{text}</Box>
        </Stack>
    );
};

export default function BulletPoints() {
    return (
        <Box pt={6}>
            <Container maxW={'7xl'} id="vantagens" >
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    <Feature
                        icon={<Icon as={FcVoicePresentation} fontSize={"6xl"} />}
                        title={'Automatização'}
                        text={
                            'Automatize a sua agenda através da utilização de um robô de atendimento diretamente no seu WhatsApp.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcOvertime} fontSize={"6xl"} />}
                        title={'Tempo Livre'}
                        text={
                            'Com o robô cuidando da sua agenda, você não precisa se preocupar em procurar horários livres ou com reagendamento/cancelamentos.'
                        }
                    />
                    <Feature
                        icon={<Icon as={FcBullish} fontSize={"6xl"} />}
                        title={'Crescimento'}
                        text={
                            'Sem precisar se preocupar com a sua agenda, você pode utilizar este tempo para focar no seu negócio.'
                        }
                    />
                </SimpleGrid>
            </Container>
        </Box>
    );
}