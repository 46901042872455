import About from "../Components/Home/About";
import Customers from "../Components/Home/Customers";
import Features from "../Components/Agendador/Features";
import Hero from "../Components/Agendador/Hero";
import Layout from "../Components/Layout";
import Pricing from "../Components/Agendador/Pricing";
import Video from "../Components/Agendador/Video";
import Ask from "../Components/Ask";
import { useEffect } from "react";
import Referral from "../Components/Agendador/Referral";

export default function Agendador() {
    useEffect(() => {
        window.fbq && window.fbq('track', 'ViewContent', { content_name: 'Agendador', content_type: 'product', content_ids: ['Agendador'] });
    }, []);

    return (
        <Layout navItems={[
            {
                label: 'Demonstração',
                href: '#demonstracao',
            },
            {
                label: 'Clientes',
                href: '#clientes',
            },
            {
                label: 'Funcionalidades',
                href: '#funcionalidades',
            },
            {
                label: 'Preço',
                href: '#preco',
            },
            {
                label: 'Indicações',
                href: '#indicacao',
            }]}>
            <Hero />
            <Video />
            <Customers />
            <Features />
            <Pricing showPrice={true} />
            <Referral />
            <About />
            <Ask />
        </Layout>
    )
}