import './App.css';
// import Home from './Pages/Home';
import { Route, Routes } from "react-router-dom";
import Agendador from './Pages/Agendador';
import TermsOfUsage from './Pages/TermsOfUsage';
import PrivacyPolice from './Pages/PrivacyPolice';

function getDomainWithoutSubdomain(url) {
    if (url === "127.0.0.1") return url;

    const urlParts = url.split(".");

    return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join(".");
}

function setCookie(name, value) {
    let maxAge = (60 * 60 * 24 * 30); // Max age / 30 Days

    document.cookie = `${name}=${(value || "")}; max-age=${maxAge}; path=/; domain=${getDomainWithoutSubdomain(window.location.hostname)}`;
}

function App() {
    let params = new URLSearchParams(window.location.search);

    if (params.get('via')) {
        setCookie('via', params.get('via'), 30);
    }

    return (
        <Routes>
            <Route path={`/`} element={<Agendador />} />
            <Route path={`/robos/agendador`} element={<Agendador />} />
            <Route path={`/robos/agendador/promocao-lancamento`} element={<Agendador />} />
            <Route path={`/termos-de-uso`} element={<TermsOfUsage />} />
            <Route path={`/politicas-de-privacidade`} element={<PrivacyPolice />} />
        </Routes>
    );
}

export default App;
