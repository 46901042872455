import { SimpleGrid, Text, Stack, Flex, Container, Heading, Image, Box } from '@chakra-ui/react';


export default function Ask() {
    return (
        <Box py={5} bg={"gray.100"}>
            <Container my={{ base: 0, md: 20}} mb={{base: 0, md: 20}} maxW={'5xl'} id="ask" py={{ base: 10, md: 0 }} >
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Stack spacing={4}>
                        <Heading fontSize={"2xl"}>Dúvidas?</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            Ainda ficou com alguma dúvida sobre o sistema e/ou robô? Nos chame no WhatsApp agora mesmo! Ficaremos felizes em te ajudar, não perca essa oportunidade. Clique no botão verde na parte inferior da sua tela.
                        </Text>
                    </Stack>
                    <Flex>
                        <Image
                            h={"50%"}
                            w="auto"
                            rounded={'md'}
                            src={'/images/questions.png'}
                            objectFit={'cover'}
                        />
                    </Flex>
                </SimpleGrid>
            </Container>
        </Box>
    );
}