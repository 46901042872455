import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    List,
    ListItem,
    ListIcon,
    Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import sendGtagConversion from '../../Helpers/Helper';
import { TbArrowRight } from 'react-icons/tb';

function PriceWrapper({ children }) {
    return (
        <Box
            maxW="90%"
            mb={4}
            shadow="xl"
            borderWidth="1px"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
            borderColor={'gray.200'}
            borderRadius={'xl'}>
            {children}
        </Box>
    );
}

export default function Pricing({ showPrice = false }) {
    return (
        <Box py={12} id="preco">
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Preço
                </Heading>
                <Text fontSize="lg" color={'gray.500'}>
                    Teste grátis por 7 dias, nenhum cartão de crédito necessário, cancele quando quiser.
                </Text>
            </VStack>
            <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}>
                <PriceWrapper>
                    <Box position="relative">
                        <Box
                            position="absolute"
                            top="-16px"
                            left="50%"
                            style={{ transform: 'translate(-50%)' }}>
                            <Text
                                textTransform="uppercase"
                                bg={"whatsapp.400"}
                                px={3}
                                py={1}
                                color={"white"}
                                fontSize="xs"
                                fontWeight="600"
                                rounded="xl"
                                whiteSpace={"nowrap"}
                            >Plano único
                            </Text>
                        </Box>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                Mensalidade
                            </Text>
                            {/* {
                                showPrice ?
                                <>
                                    <Text w="100%" color={"red.400"} textDecoration="line-through" mt={1}>
                                        De R$ 159,90/mês
                                    </Text>
                                    <Text w="100%" mt={1} fontStyle="italic" fontSize={"sm"}>
                                        por apenas
                                    </Text>
                                </>
                                :
                                ''
                            } */}
                            {
                                showPrice ?
                                    <HStack justifyContent="center">
                                        <Text fontSize="3xl" fontWeight="600">
                                            R$
                                        </Text>
                                        <Text fontSize="5xl" fontWeight="900">
                                            89,90
                                        </Text>
                                        <Text fontSize="3xl" color="gray.500">
                                            /mês
                                        </Text>
                                    </HStack>
                                    :
                                    <VStack justifyContent="center" spacing={0}>
                                        <Text fontSize="3xl" fontWeight="600">
                                            Consulte
                                        </Text>
                                        <Text color="gray.500">
                                            com seu vendedor
                                        </Text>
                                        <Text color="gray.500">
                                            ou nos chame no chat abaixo.
                                        </Text>
                                    </VStack>
                            }
                        </Box>
                        <VStack
                            bg={'gray.50'}
                            py={4}
                            borderBottomRadius={'xl'}>
                            <List spacing={3} textAlign="start" px={12}>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Robô de atendimento no WhatsApp
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Sistema de Gerenciamento
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Relatórios
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Lembretes
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Agenda Online
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Múltiplos Atendentes
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    Acesso Ilimitado
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={FaCheckCircle} color="green.500" />
                                    E muito mais...
                                </ListItem>
                            </List>
                            <Box w="80%" pt={7}>
                                <Button
                                    w={'full'}
                                    fontWeight={'normal'}
                                    px={6}
                                    colorScheme={'whatsapp'}
                                    as={'a'}
                                    onClick={() => sendGtagConversion()}
                                    href="https://app.robotizap.com/register?type=booking"
                                    rightIcon={<TbArrowRight/>}
                                >
                                    Criar Conta
                                </Button>
                                <Text opacity={.75} mt={1}>Teste grátis por 7 dias</Text>
                            </Box>
                        </VStack>
                    </Box>
                </PriceWrapper>
{/*                 
                <PriceWrapper>
                    <Box py={4} px={12}>
                        <Text fontWeight="500" fontSize="2xl">
                            Agendamentos Extras
                        </Text>
                    {
                        showPrice ?
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    R$
                                </Text>
                                <Text fontSize="5xl" fontWeight="900">
                                    45,00
                                </Text>
                            </HStack>
                            :
                            <VStack justifyContent="center" spacing={0}>
                                <Text fontSize="3xl" fontWeight="600">
                                    Consulte
                                </Text>
                                <Text color="gray.500">
                                    com seu vendedor
                                </Text>
                                <Text color="gray.500">
                                    ou nos chame no chat abaixo.
                                </Text>
                            </VStack>
                        }
                    </Box>
                    <VStack
                        bg={useColorModeValue('gray.50', 'gray.700')}
                        py={4}
                        borderBottomRadius={'xl'}>
                        <List spacing={3} textAlign="start" px={12}>
                            <ListItem>
                                <ListIcon as={FaCheckCircle} color="green.500" />
                                Pacote com 200 agendamentos
                            </ListItem>
                            <ListItem>
                                <ListIcon as={FaInfoCircle} color="blue.500" />
                                Ativado quando nescessário
                            </ListItem>
                        </List>
                    </VStack>
                </PriceWrapper> */}
            </Stack>
        </Box>
    );
}