import {
    Box,
    chakra,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Image,
    Button,
} from '@chakra-ui/react';
import { FaExternalLinkAlt, FaFacebook, FaInstagram, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import sendGtagConversion from '../Helpers/Helper';

const SocialButton = ({
    children,
    label,
    href,
}) => {
    return (
        <chakra.button
            bg={'blackAlpha.100'}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            target="_blank"
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'blackAlpha.200',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

const ListHeader = ({ children }) => {
    return (
        <Box fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Box>
    );
};

export default function Footer() {
    return (
        <Box bg={"white"} 
        borderTop={1}
        borderStyle={'solid'}
        borderColor={'gray.200'}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}
                >
                    <Stack spacing={6}>
                        <Box>
                            <Image mx={{ base: "auto", md: "0" }} src='/images/robotizap_light.png' height={10} alt='logo' />
                        </Box>
                        <Box textAlign={{ base: "center", md: "left" }}>
                            <Text fontSize={'sm'}>
                                RobotiZap © {(new Date()).getFullYear()}. Todos os direitos reservados.
                            </Text>
                            <Text fontSize={'sm'}>
                                Desenvolvido e mantido por PK Systems.
                            </Text>
                            <Text fontSize={'sm'}>
                                CNPJ 43.456.936/0001-34
                            </Text>
                        </Box>
                        <Stack direction={'row'} spacing={6} justify={{ base: "center", md: 'flex-start'}}>
                            <SocialButton label={'Twitter'} href={'https://twitter.com/robotizap'}>
                                <FaTwitter />
                            </SocialButton>
                            <SocialButton label={'YouTube'} href={'https://www.youtube.com/channel/UCz0qyIB4A9nlLXhGrgMClJw'}>
                                <FaYoutube />
                            </SocialButton>
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/robotizap'}>
                                <FaInstagram />
                            </SocialButton>
                            <SocialButton label={'Facebook'} href={'https://www.facebook.com/robotizap'}>
                                <FaFacebook />
                            </SocialButton>
                            <SocialButton label={'WhatsApp'} href={'https://wa.me/5551999168875?text=Olá! Vim pelo site do RobotiZap'}>
                                <FaWhatsapp />
                            </SocialButton>
                        </Stack>
                    </Stack>
                    <Stack align={{ base: "center", md: 'flex-start'}}>
                        <ListHeader>Acesso Rápido</ListHeader>
                        <Link href={'https://app.robotizap.com/login'}>Acessar Sistema</Link>
                        {/* <Link href={'/#robos'}>Robôs</Link> */}
                        <Link href={'/#sobre'}>Sobre Nós</Link>
                        <Link href={'/#clientes'}>Clientes</Link>
                    </Stack>
                    <Stack align={{ base: "center", md: 'flex-start'}}>
                        <ListHeader>Ajuda</ListHeader>
                        <Text>
                            email@robotizap.com
                        </Text>
                        <Text>
                            Lajeado, RS, Brasil
                        </Text>
                        <Link whiteSpace={"nowrap"} href={'/termos-de-uso'}>Termos de Uso</Link>
                        <Link whiteSpace={"nowrap"} href={'/politicas-de-privacidade'}>Políticas de Privacidade</Link>
                    </Stack>
                    <Stack align={{ base: "center", md: 'flex-start'}}>
                        <ListHeader>Teste grátis</ListHeader>
                        <Stack direction={'column'} textAlign={{ base: "center", md: 'left'}}>
                            <Text>
                                Crie sua conta agora e tenha 7 dias de teste grátis de todas as funcionalidades do sistema.
                            </Text>
                            <Button
                                as={'a'}
                                fontSize={'sm'}
                                fontWeight={600}
                                color={'white'}
                                bg={'whatsapp.400'}
                                onClick={() => sendGtagConversion()}
                                href={'https://app.robotizap.com/register?type=booking'/* + (window.location.href.indexOf('/agendador') !== -1 ? "?type=booking" : "")*/}
                                _hover={{
                                    bg: 'whatsapp.300',
                                }}
                                leftIcon={<FaExternalLinkAlt/>}
                            >
                                Criar minha conta
                            </Button>
                        </Stack>
                    </Stack>
                </SimpleGrid>
                <Box pb={{ base: 5, md: 0 }}>
                    <Text textAlign={"center"} fontSize="xs" opacity={.75} mt={4}>
                        RobotiZap é um produto da empresa independente PK Systems.<br/>
                        Desenvolvemos soluções para comunicação, automatização e gerenciamento.<br/>
                        Não possuímos qualquer vínculo de filiação com a WhatsApp Inc. ou Meta
                    </Text>
                </Box>
            </Container>
        </Box>
    );
}