import {
    Box,
    Flex, IconButton,
    Button,
    Stack,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useDisclosure,
    Image,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { TbArrowRight, TbBrandWhatsapp } from "react-icons/tb";
import Footer from './Footer';
import sendGtagConversion from '../Helpers/Helper';

export default function Layout({ children, navItems }) {
    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        <>
            <Box
                position={"sticky"}
                zIndex={10}
                top={0}
            >
                <Flex
                    bg={'white'}
                    color={'gray.600'}
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    borderBottom={1}
                    borderStyle={'solid'}
                    borderColor={'gray.200'}
                    align={'center'}
                    justifyContent={"space-between"}
                >
                    <Flex
                        ml={{ base: -2, md: 0 }}
                        align="center"
                        gap={1}
                    >
                        <IconButton
                            display={{ base: 'flex', md: 'none' }}
                            onClick={onToggle}
                            icon={
                                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                            }
                            variant={'ghost'}
                            aria-label={'Toggle Navigation'}
                        />
                        <Box
                            fontFamily={'heading'}
                            color={'gray.800'}
                        >
                            <Button
                                as={'a'}
                                variant={'link'}
                                href={'/'}
                            >
                                <Image src='/images/robotizap_light.png' maxH={"30px"} h="auto" w="auto" alt='logo' mt={1} />
                            </Button>
                        </Box>

                        <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                            <DesktopNav navItems={navItems} />
                        </Flex>
                    </Flex>

                    <Stack
                        flex={{ base: 1, md: 0 }}
                        justify={'flex-end'}
                        direction={'row'}
                        spacing={6}
                    >
                        <Button
                            as={'a'}
                            display={{ base: 'none', md: 'inline-flex' }}
                            fontSize={'sm'}
                            fontWeight={400}
                            variant={'link'}
                            href={'https://app.robotizap.com'}
                        >
                            Acessar
                        </Button>
                        <Button
                            as={'a'}
                            display={'inline-flex'}
                            size={"sm"}
                            fontWeight={600}
                            color={'white'}
                            bg={'whatsapp.400'}
                            onClick={() => sendGtagConversion()}
                            href={'https://app.robotizap.com/register?type=booking'/* + (window.location.href.indexOf('/agendador') !== -1 ? "?type=booking" : "")*/}
                            _hover={{
                                bg: 'whatsapp.300',
                            }}
                            rightIcon={<TbArrowRight />}
                        >
                            Testar Grátis
                        </Button>
                    </Stack>
                </Flex>


                <MobileNav isOpen={isOpen} onClose={onClose} navItems={navItems} />
            </Box>
            <Box>
                {children}
            </Box>
            <Footer />

            <Flex position={"fixed"} right={{ base: undefined, md: 6 }} bottom={{ base: 4, md: 6 }} w={{ base: "100vw", md: "auto" }} justify={"center"}>
                <Button
                    as="a"
                    href="https://wa.me/5551999168875?text=Olá! Vim pelo site, estou com dúvidas!"
                    target="_blank"
                    id='whats-bt'
                    leftIcon={<Icon as={TbBrandWhatsapp}
                        fontSize={"2xl"} />}
                    shadow="lg"
                    colorScheme='whatsapp'
                    size="md"
                    rounded={"full"}
                    mx={"auto"}
                >
                    Dúvidas? Chame no Whats
                </Button>
            </Flex>
        </>
    );
}

const DesktopNav = ({ navItems = [] }) => {
    const linkColor = 'gray.600';
    const linkHoverColor = 'gray.800';
    const popoverContentBgColor = 'white';

    return (
        <Stack direction={'row'} spacing={4}>
            {navItems.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize={'sm'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: 'whatsapp.50' }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Box
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'whatsapp.400' }}
                        fontWeight={500}>
                        {label}
                    </Box>
                    <Box fontSize={'sm'}>{subLabel}</Box>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'whatsapp.500'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = ({ isOpen, navItems = [], onClose }) => {
    return (
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                    <Image src='/images/robotizap_light.png' h={"40px"} alt='Logo RobotiZap' />
                </DrawerHeader>

                <DrawerBody>
                    <Stack
                        bg={'white'}
                        p={4}
                        display={{ md: 'none' }}>
                        {navItems.map((navItem) => (
                            <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
                        ))}
                    </Stack>

                    <Button
                        as={'a'}
                        display={'inline-flex'}
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'whatsapp.400'}
                        onClick={() => sendGtagConversion()}
                        href={'https://app.robotizap.com/register?type=booking'/* + (window.location.href.indexOf('/agendador') !== -1 ? "?type=booking" : "")*/}
                        _hover={{
                            bg: 'whatsapp.300',
                        }}
                        rightIcon={<TbArrowRight />}
                    >
                        Testar Grátis
                    </Button>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

const MobileNavItem = ({ label, href, onClose }) => {
    return (
        <Stack spacing={4} onClick={() => { onClose() }}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Box
                    fontWeight={600}
                    color={'gray.600'}>
                    {label}
                </Box>
            </Flex>
        </Stack>
    );
};

// const NAV_ITEMS = [
//     {
//         label: 'Vantagens',
//         href: '/#vantagens',
//     },
//     {
//         label: 'Robôs',
//         href: '/#robos',
//     },
//     {
//         label: 'Sobre nós',
//         href: '/#sobre',
//     },
//     {
//         label: 'Clientes',
//         href: '/#clientes',
//     },
//     // {
//     //     label: 'Conheça os Robôs',
//     //     children: [
//     //         {
//     //             label: 'Agendamento',
//     //             subLabel: 'Deixe um robô gerenciar a agenda do seu negócio.',
//     //             href: '#',
//     //         }
//     //     ],
//     // },
// ];