import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import Layout from "../Components/Layout";

export default function PrivacyPolice() {
    return (
        <Layout navItems={[
            {
                label: 'Vantagens',
                href: '/#vantagens',
            },
            {
                label: 'Robôs',
                href: '/#robos',
            },
            {
                label: 'Sobre nós',
                href: '/#sobre',
            },
            {
                label: 'Clientes',
                href: '/#clientes',
            }]}>
            <Box bg={"gray.100"} py={5}>
                <Container my={{ base: 0, md: 10 }} mb={{ base: 10, md: 20 }} maxW={'4xl'} id="funcionalidades">
                    <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                        <Heading fontSize={'3xl'}>Políticas de Privacidade</Heading>
                    </Stack>

                    <Box mt={4}>
                    Nossa política de privacidade explica como as informações são coletadas, usadas e divulgadas pela RobotiZap e são válidas para todos os usuários.<br/><br/>
                    <b>Quais informações coletamos?</b><br/>
                    Nós coletamos algumas informações que você nos fornece quando usa a RobotiZap, por exemplo: Quando você cria uma conta, nos envia um email, insere dados ou outro conteúdo, nós coletamos algumas informações pessoais como seu nome, foto, endereço de email e número de telefone.<br/>
                    Nós também coletamos automaticamente determinadas informações quando você usa a RobotiZap, o identificador do dispositivo, endereço MAC, o endereço Internet Protocol (IP), sistema operacional, o tipo de navegador e sua atividade na RobotiZap. Esta informação não é tratada como informação pessoal a não ser que combine ou vincule isto com qualquer informação de identificação pessoal mencionado acima.<br/>
                    Também podemos coletar automaticamente determinadas informações através do uso de cookies. Estes são pequenos arquivos que o seu navegador coloca em seu computador. Nós podemos utilizar tanto session cookies como persistent cookies para entender melhor como você interage com os nossos serviços, monitorar o uso agregado por nossos usuários e roteamento de tráfego web nos nossos serviços, e melhorar os nossos serviços. A maioria dos navegadores aceitam cookies automaticamente. Você pode instruir seu navegador, editando estas opções para parar de aceitar os cookies ou consultar antes de aceitar um cookie dos sites que você visita.
                    <br/><br/>
                    <b>Como nós utilizamos as informações coletadas?</b><br/>
                    Nós usamos as informações coletadas através da RobotiZap para os seguintes fins:<br/>
                    Para fornecer nossos serviços ou informações solicitadas, e para processar e completar todas as transações.<br/>
                    Responder seus email, apresentações, questões, comentários, pedidos, reclamações e prestar serviços ao cliente.<br/>
                    Monitorar e analisar maneiras de uso e tendências, personalizar e melhorar a RobotiZap e suas experiências quando você usa a RobotiZap, como fornecimento do conteúdo ou funcionalidades que correspondam ao seu perfil ou interesses (incluindo anúncios), e aumentar a funcionalidade e facilidade de uso dos nossos serviços.<br/>
                    Enviar confirmações, updates, alertas de segurança, suporte e mensagens administrativas, ajudando assim a facilitar a sua utilização, nossa administração e operação de nossos serviços.<br/>
                    Monitoramento e identificação de problemas na plataforma através de logs, auxiliando e facilitando na identificação do ponto onde ocorre o problema, acelerando assim a correção do mesmo. <br/>
                    Para qualquer outro propósito de cada informação que foi coletada.
                    <br/><br/>
                    <b>Qual informação dividimos com terceiros?</b><br/>
                    Nós não vamos dividir informações pessoais que nós coletamos de você, exceto nos casos descritos abaixo:
                    Para uso em anúncios e ferramentas de análise de dados para melhora nos serviços.<br/>
                    Com fornecedores de serviços que trabalham conosco em conexão como a operação do nosso site ou nossos serviços (esses fornecedores de serviços tem acesso as suas informações pessoais somente para executar serviços em nosso nome e são obrigados a não divulgá-las ou utilizá-las para outros fins).<br/>
                    Quando nós acreditamos de boa fé que estamos legalmente autorizados ou obrigados a fazer ou que isso seja razoavelmente necessário ou apropriado para cumprir com a lei, processos legais, responder a solicitações legais ou autoridades judiciais, incluindo responder a intimações, mandados legais ou ordens judiciais.<br/>
                    Para cumprir ou aplicar a nossa política de privacidade, nossos termos de uso, as nossas outras políticas ou acordos; e em conexão com, ou durante as negociações de, qualquer fusão, venda de ativos da empresa, financiamento ou aquisições, ou em qualquer outra situação onde as informações pessoais podem ser divulgadas ou transferidas como um dos nossos negócios.<br/>
                    Nós não somos responsáveis por essas ações do fornecedor de serviços ou de terceiros, nem somos responsáveis por qualquer informação adicional que você fornece diretamente para terceiros.
                    <br/><br/>
                    <b>Segurança</b><br/>
                    Estamos muito preocupados em guardar a confidencialidade de suas informações pessoalmente identificáveis. Implementamos uma série de medidas de segurança administrativas, físicas e eletrônicas para proteger suas informações contra acesso não autorizado.<br/>
                    Nós não vendemos, comercializamos, ou de outra forma, transferimos a terceiros as suas informações pessoalmente identificáveis.
                    <br/><br/>
                    <b>Links para outros sites</b><br/>
                    A RobotiZap contém links para outros sites. O fato de que um link para um site não é um endosso/aval, autorização ou representação da nossa filiação com esse terceiro. Nós não exercemos controle sobre sites de terceiros. Esses outros sites podem colocar seus próprios cookies ou outros arquivos no seu computador, coletar dados ou solicitar informações pessoais sobre você. Outros sites de seguir regras diferentes em relação à utilização ou divulgação das informações pessoais que você enviar para eles. Nós o incentivamos a ler as políticas de privacidade ou declarações de outros sites que você visita.
                    <br/><br/>
                    <b>Mudanças na Política</b><br/>
                    Qualquer informação que é recolhida é sujeita a política de privacidade em vigor no momento em que essas informações são coletadas. Podemos, no entanto, modificar e rever a nossa política de privacidade de tempos a tempos. Ao continuar a acessar ou usar a RobotiZap após essas alterações entrarem em vigor, você concorda em se comprometer com a política revisada.
                    </Box>

                </Container>
            </Box>
        </Layout>
    )
}