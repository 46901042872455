import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    Box,
} from '@chakra-ui/react';
import { FaMoneyBill } from 'react-icons/fa';
import { TbStars } from 'react-icons/tb';

const Feature = ({ text, icon, iconBg }) => {
    return (
        <Stack direction={'row'} align={'center'}>
            <Flex
                w={8}
                h={8}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                bg={iconBg}>
                {icon}
            </Flex>
            <Text fontWeight={600}>{text}</Text>
        </Stack>
    );
};

export default function About() {
    return (
        <Box>
            <Container id="sobre" maxW={'5xl'}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                    <Flex>
                        <Image
                            alt={'feature image'}
                            src={
                                '/images/robotizap_logo_vertical.png'
                            }
                        />
                    </Flex>
                    <Stack spacing={4}>
                        <Heading>Sobre nós</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            A RobotiZap tem o objetivo de auxiliar pequenas e médias empresas a modernizarem e automatizarem seus processos à um preço justo, permitindo assim que os empreendedores tenham mais tempo para focar nos outros pontos e crescer seu negócio.
                        </Text>
                        <Stack
                            spacing={4}
                            divider={
                                <StackDivider
                                    borderColor={'gray.100'}
                                />
                            }>
                            <Feature
                                icon={
                                    <Icon as={TbStars} color={'yellow.500'} w={5} h={5} />
                                }
                                iconBg={'yellow.100'}
                                text={'Ótimo serviço'}
                            />
                            <Feature
                                icon={
                                    <Icon as={FaMoneyBill} color={'green.500'} w={5} h={5} />
                                }
                                iconBg={'green.100'}
                                text={'Preço Justo'}
                            />
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}