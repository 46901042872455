import {
    Box, Button, Container, Flex, Heading,
    Image,
    SimpleGrid,
    Stack,
    Text
} from '@chakra-ui/react';
import sendGtagConversion from '../../Helpers/Helper';
import { TbArrowRight } from 'react-icons/tb';


export default function Referral() {
    return (
        <Box py={12} id="indicacao" bg="gray.100">
            <Container maxW={'5xl'}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center" flexDirection={{ base: "column-reverse", md: "row" }} display={{ base: "flex", md: "grid" }}>

                    <Stack spacing={4}>
                        <Heading>Indicações</Heading>
                        <Text color={'gray.500'} fontSize={'lg'}>
                            Indique e ganhe <b>20%</b> de todos os pagamentos de seus indicados
                        </Text>

                        <Text color={'gray.500'} mt={4}>
                            Receba comissões mensalmente de todos os clientes indicados por você, tenha uma nova e consistente
                            fonte de renda agora mesmo. Crie sua conta, e comece a enviar seu link.
                        </Text>

                        <Text color={'gray.500'} mt={4}>
                            Pagamos as comissões via split de pagamentos, ou seja, você recebe a sua comissão no momento que o cliente efetuar o pagamento.
                        </Text>

                        <Flex justify={"center"}>
                            <Button
                                w={'auto'}
                                fontWeight={'normal'}
                                px={6}
                                colorScheme={'whatsapp'}
                                as={'a'}
                                onClick={() => sendGtagConversion()}
                                href="https://app.robotizap.com/register?type=referral"
                                rightIcon={<TbArrowRight />}
                            >
                                Criar conta e começar a indicar
                            </Button>
                        </Flex>

                    </Stack>
                    <Flex>
                        <Image
                            alt={'feature image'}
                            src={
                                '/images/referral.webp'
                            }
                        />
                    </Flex>
                </SimpleGrid>
            </Container>
        </Box>
    );
}