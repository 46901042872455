import { Box, Button, Center, Container, Flex, GridItem, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import sendGtagConversion from "../../Helpers/Helper";

export default function Video() {
    return (
        <Box bg={"gray.100"} py={1}>
            <Container my={{ base: 10, md: 20 }} mb={{ base: 10, md: 14 }} maxW={'7xl'} id="demonstracao">
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={'3xl'}>Demonstração</Heading>
                </Stack>

                <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{base: 10, lg: 4}} mt={8}>
                    <GridItem flexDirection={{ base: "column-reverse", lg: "column"}} as={Flex} gap={4}>
                        <Text textAlign={"center"} color={'gray.600'} fontSize={'md'} maxW="sm" mx="auto">
                            Veja como é simples para os seus clientes agendarem um horário no seu WhatsApp.
                        </Text>
                        <Center>

                            <Box p={4} shadow="lg" bg="white" rounded="lg">
                                <Heading fontSize={'xl'} mb={3} textAlign="center">Agendamento via WhatsApp</Heading>
                                <Box
                                    as='iframe'
                                    src='https://www.youtube.com/embed/kW6sCbu_f1s'
                                    width={720 / 2}
                                    height={'auto'}
                                    maxW='100%'
                                    sx={{
                                        aspectRatio: '9/16'
                                    }}
                                    allowFullScreen
                                />
                            </Box>
                        </Center>
                    </GridItem>
                    <GridItem flexDirection={{ base: "column-reverse", lg: "column"}} as={Flex} gap={4}>
                        <Text textAlign={"center"} color={'gray.600'} fontSize={'md'} maxW="sm" mx="auto">
                            E você tem acesso a todas os recursos do sistema diretamente no dashboard!
                        </Text>
                        <Center>

                            <Flex p={4} shadow="lg" bg="white" rounded="lg" gap={4} direction={"column"}>
                                <Heading fontSize={'xl'} mb={3} textAlign="center">Gerenciamento via Celular ou Computador</Heading>
                                <Box
                                    as='img'
                                    src='/images/booking/robotizap-dashboard.png'
                                    width={"full"}
                                    height={'auto'}
                                    rounded={"lg"}
                                    shadow="sm"
                                />
                                <Box
                                    as='img'
                                    src='/images/booking/robotizap-dashboard2.png'
                                    width={"full"}
                                    height={'auto'}
                                    rounded={"lg"}
                                    shadow="sm"
                                />
                            </Flex>
                        </Center>
                    </GridItem>
                </SimpleGrid>

                <Flex justify={"center"} mt={6}>
                    <Button
                        w={'auto'}
                        fontWeight={'normal'}
                        px={6}
                        colorScheme={'whatsapp'}
                        as={'a'}
                        onClick={() => sendGtagConversion()}
                        href="https://app.robotizap.com/register?type=booking"
                    >
                        Testar grátis por 7 dias
                    </Button>
                </Flex>
            </Container>
        </Box>
    );
}