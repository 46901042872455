import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
} from '@chakra-ui/react';
import { BiBuilding, BiMap } from 'react-icons/bi';
import { FaRobot } from 'react-icons/fa';

const Testimonial = ({ children }) => {
    return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
    return (
        <Stack
            bg={'white'}
            boxShadow={'lg'}
            border={"1px solid"}
            borderColor={"gray.100"}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: 'white',
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    );
};

const TestimonialHeading = ({ children }) => {
    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    );
};

const TestimonialText = ({ children }) => {
    return (
        <Box
            textAlign={'center'}
            color={'gray.600'}
            fontSize={'sm'}>
            {children}
        </Box>
    );
};

const TestimonialAvatar = ({
    src,
    name,
    title,
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} alt={name} mb={2} />
            <Stack spacing={-1} align={'center'}>
                <Box fontWeight={600}>{name}</Box>
                <Box fontSize={'sm'} color={'gray.600'}>
                    {title}
                </Box>
            </Stack>
        </Flex>
    );
};

export default function Customers() {
    return (
        <Box id="clientes" >
            <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
                <Stack spacing={0} align={'center'}>
                    <Heading>Nossos Clientes</Heading>
                    <Text>Saiba como o RobotiZap impactou nossos clientes</Text>
                </Stack>
                <Stack
                    direction={{ base: 'column', md: 'row' }}
                    spacing={{ base: 10, md: 4, lg: 10 }}
                >
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>Aprovado</TestimonialHeading>
                            <TestimonialText>
                                Sistema ótimo de trabalhar: simples, prático e muito eficiente... <b>Todos clientes estão aprovando pela facilidade e praticidade no manuseio.</b> Gostei muito, deu um UP tremendo nos atendimentos na barbearia.
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={
                                '/images/customers/customer_leandro.jpg'
                            }
                            name={'Leandro Fell'}
                            title={
                                <Flex justify={"center"} direction="column">
                                    <Flex justify={"center"} align={"center"} gap={1}><BiBuilding/> Barbus Barbearia</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><BiMap/> Lajeado/RS</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><FaRobot/> Agendador</Flex>
                                </Flex>
                            }
                        />
                    </Testimonial>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>Melhor Sistema do Mercado</TestimonialHeading>
                            <TestimonialText>
                                Minha experiência com o RobotiZap foi muito acima do esperado, antes eu tinha um sistema muito burocrático que poucos clientes realmente usavam, quando passei a usar o agendamento pelo WhatsApp todos meus clientes elogiaram muito a praticidade do sistema, da rapidez e simplicidade que faz com que seja muito fácil usar, <b>eu com 11 anos de barbearia posso dizer que foi o melhor sistema que eu já testei.</b>
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={
                                '/images/customers/delta-djeison.jpg'
                            }
                            name={'Djeison Stürmer'}
                            title={
                                <Flex justify={"center"} direction="column">
                                    <Flex justify={"center"} align={"center"} gap={1}><BiBuilding/> Barbearia Delta</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><BiMap/> Lajeado/RS</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><FaRobot/> Agendador</Flex>
                                </Flex>
                            }
                        />
                    </Testimonial>
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>Clientes aprovam</TestimonialHeading>
                            <TestimonialText>
                            O sistema é prático, meus clientes estão gostando muito, <b>essa questão de não ter um cadastro complicado facilita muito, apenas com número e nome, prático, todas idades conseguem usar.</b> Está cada vez melhor, muito prático, sempre compreendendo nossa necessidade
                            </TestimonialText>
                        </TestimonialContent>
                        <TestimonialAvatar
                            src={
                                '/images/customers/daniel.jpg'
                            }
                            name={'Daniel Krämer'}
                            title={
                                <Flex justify={"center"} direction="column">
                                    <Flex justify={"center"} align={"center"} gap={1}><BiBuilding/> Barbeiro Daniel</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><BiMap/> Lajeado/RS</Flex>
                                    <Flex justify={"center"} align={"center"} gap={1}><FaRobot/> Agendador</Flex>
                                </Flex>
                            }
                        />
                    </Testimonial>
                </Stack>
            </Container>
        </Box>
    );
}