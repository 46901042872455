import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    Icon,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import sendGtagConversion from '../../Helpers/Helper';
import { TbArrowRight } from "react-icons/tb";

export default function Hero() {
    useEffect(() => {
        document.title = 'Robo Agendador - RobotiZap';
    }, []);

    return (
        <Box pb={{ base: 0, md: 12 }}>
            <Container maxW={'7xl'} >
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 10 }}
                    py={10}
                    direction={{ base: 'column', md: 'row' }}>
                    <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '2xl', sm: '4xl', lg: '2.75rem' }}>
                            <Text
                                as={'span'}
                                color="gray.700"
                                position={'relative'}
                                _after={{
                                    content: "''",
                                    width: 'full',
                                    height: '25%',
                                    position: 'absolute',
                                    bottom: 1,
                                    left: 0,
                                    bg: 'whatsapp.400',
                                    zIndex: -1,
                                }}
                                whiteSpace="nowrap"
                            >
                                Robô agenda no WhatsApp
                            </Text>
                            <br />
                            <Text as={'span'} color={'whatsapp.400'} fontSize={"80%"}>
                                Não fique para trás, atualize-se!
                            </Text>
                        </Heading>
                        <Text color={'gray.500'}>
                            Permita que seus clientes agendem horários diretamente com um robô de atendimento no chat do WhatsApp, sem instalar nenhum aplicativo, acessar sites ou criar cadastros.<br /> Nosso robô agendador se adequa a qualquer negócio que precisa de agenda, entre eles: Barbearias, Salões de Beleza, Clínicas, Consultórios, Escritórios, etc.
                        </Text>
                        <Stack
                            spacing={{ base: 4, sm: 6 }}
                            direction={{ base: 'column', sm: 'row' }}>
                            <Flex direction={"column"} gap={1}>
                                <Button
                                    rounded={'full'}
                                    size={'lg'}
                                    fontWeight={'normal'}
                                    px={6}
                                    colorScheme={'whatsapp'}
                                    as={'a'}
                                    onClick={() => sendGtagConversion()}
                                    href="https://app.robotizap.com/register?type=booking"
                                    rightIcon={<TbArrowRight />}
                                >
                                    Quero Testar Grátis por 7 dias
                                </Button>
                                <Text textAlign={"center"} fontSize={"xs"} fontStyle={"italic"} opacity={.5}>Nenhum cartão de crédito necessário, teste sem compromisso.</Text>
                            </Flex>
                            <Button
                                rounded={'full'}
                                size={'lg'}
                                fontWeight={'normal'}
                                px={6}
                                as={'a'}
                                href="#demonstracao"
                                rightIcon={<ChevronDownIcon />}
                            >
                                Conhecer mais
                            </Button>
                        </Stack>

                    </Stack>
                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}>
                        <Box
                            position={'relative'}
                            height={'auto'}
                            width={'auto'}
                            overflow={'hidden'}
                        >
                            <Image
                                alt={'Hero Image'}
                                // fit={'contain'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                // src={'/images/booking/bot.png'}
                                src={'/images/robotizap-mock-pc-phone.webp'}
                            />
                        </Box>
                    </Flex>
                </Stack>
            </Container>
        </Box>
    );
}
export const Blob = (props) => {
    return (
        <Icon
            width={'100%'}
            viewBox="0 0 578 440"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
                fill="currentColor"
            />
        </Icon>
    );
};