import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    Flex,
    Image,
    useBreakpointValue,
    Badge,
    Button,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import sendGtagConversion from '../../Helpers/Helper';
import BulletPoints from './BulletPoints';

const features = [
    {
        title: 'Gerenciamento da agenda',
        text: 'Disponibilizamos uma agenda cheia de recursos para que os profissionais possam acompanhar e gerenciar o seu dia a dia. Além das alterações automáticas que são realizadas pelo robô de atendimento, você também pode fazer ações manuais caso precise, como por exemplo: novos agendamentos, remarcações, cancelamentos, solicitações de reagendamento e consultas.',
        image: '/images/booking/calendar.png',
        fit: 'cover',
        featured: true
    },
    {
        title: 'Robô de atendimento',
        text: 'Não se preocupe com o gerenciamento da sua agenda, o nosso robô faz tudo por você. Seus clientes realizam todo o processo de forma fácil, rápida e intuitiva, não precisam instalar nenhum aplicativo, tudo é diretamente no chat do WhatsApp. O robô organiza a sua agenda quando houverem novos agendamentos, cancelamentos ou reagendamentos, você só precisa se preocupar em atender seus clientes.',
        image: '/images/booking/bot.png',
        featured: true
    },
    {
        title: 'Múltiplos atendentes',
        text: 'Seu negócio possui mais de um profissional? Não tem problemas, o sistema possui total suporte e gerencia a agenda de todos os atendentes de forma individual, assim caso seu cliente tenha um profissional preferido, ele pode continuar sendo atendido por ele.',
        image: '/images/booking/multiple_attendants.png',
        featured: true
    },
    {
        title: 'Lembrete de agendamento',
        text: 'Reduza o índice de faltas dos seus clientes enviando lembretes automáticos para eles, você personaliza a mensagem e quanto tempo antes do horário agendado ela será enviada, não perca mais horários e dinheiro por esse motivo.',
        image: '/images/booking/reminder.png',
        featured: true
    },
    {
        title: 'Confirmação de comparecimento',
        text: 'Assim como o lembrete, você pode optar por enviar uma solicitação de confirmação de comparecimento para o seu cliente, assim evitando faltas e liberando o horário para outro cliente interessado, evitando a perca do horário e prejuízo.',
        image: '/images/booking/confirmation.png',
        featured: true
    },
    {
        title: 'Avaliação do atendimento',
        text: 'Após o atendimento, caso você ative, o sistema enviará para o seu cliente uma solicitações de avaliação do atendimento, onde ele pode dar uma nota de 1 a 5. Você customiza a mensagem e quanto tempo após o atendimento deverá ser enviada.',
        image: '/images/booking/review.png',
        featured: true
    },
    {
        title: 'Programa de Fidelidade',
        text: 'Fidelize seus clientes, permitindo-os acumularem pontos e trocarem por serviços gratuitos, você configura os pontos que cada serviço gera e quantos são nescessários para obté-lo gratuitamente.',
        image: '/images/booking/rewards.png',
        featured: true
    },
    {
        title: 'Pagamentos Online',
        text: 'Colete pagamentos dos serviços via Cartão de Crédito e PIX diretamente na conversa de seu cliente com o robô, o dinheiro cai diretamente na sua conta.',
        image: '/images/booking/payments.png',
        featured: true
    },
    {
        title: 'Customizações',
        text: 'Customize as mensagens que são enviadas pelo robô aos seus clientes, como: lembrete, avaliação, confirmação, etc...',
    },
    {
        title: 'Horário de atendimento',
        text: 'Cada profissional pode ter um horário de atendimento customizado e horários diferenciados em certos dias, como folgas e feriados.',
    },
    {
        title: 'Atendimento a Domicílio',
        text: 'Se você não tem ponto fixo e atende a domicílio, nosso sistema também lhe atende, inclusive você pode limitar os CEPs onde atende.',
    },
    {
        title: 'Relatórios',
        text: 'Tenha acesso à relatórios do seu faturamento, quantidade de agendamentos e muito mais...',
    },
    {
        title: 'Mensagem de retorno',
        text: 'Mensagem incentivando o retorno do cliente após um certo período sem agendamentos.',
    },
    {
        title: 'Venda produtos',
        text: 'Caso você tenha produtos, você poderá ofertá-los juntamente aos seus serviços.',
    },
];

export default function Features() {
    return (
        <Box bg={"gray.100"} py={5}>
            <Container my={{ base: 0, md: 20 }} mb={{ base: 10, md: 20 }} maxW={'7xl'} id="funcionalidades">
                <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                    <Heading fontSize={'3xl'}>Funcionalidades</Heading>
                    <Text color={'gray.600'} fontSize={'xl'}>
                        Conheça algumas das funcionalidades que o Robô Agendador oferece para você.
                    </Text>
                </Stack>

                <Container maxW={'6xl'}>
                    {features.filter(f => f.featured).map((feature, id) => (
                        <Feature isOdd={id % 2 === 0} key={id} title={feature.title} text={feature.text} image={feature.image} />
                    ))}

                    <Container spacing={4} maxW={'3xl'} textAlign={'center'} mb={4} mt={4}>
                        <Heading fontSize={'2xl'}>E muito mais...</Heading>
                    </Container>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                        {features.filter(f => !f.featured).map((feature) => (
                            <HStack align={'top'}>
                                <Box color={'green.400'} px={2}>
                                    <Icon as={CheckIcon} />
                                </Box>
                                <VStack align={'start'}>
                                    <Box fontWeight={600}>
                                        {
                                            feature.soon ?
                                                <Badge colorScheme={"whatsapp"} mr={1}>Em breve</Badge>
                                            :
                                                ""
                                        }
                                        {feature.title}
                                    </Box>
                                    <Text color={'gray.600'}>{feature.text}</Text>
                                </VStack>
                            </HStack>
                        ))}
                    </SimpleGrid>

                    <BulletPoints />
                </Container>
            </Container>


            <Flex justify={"center"} mb={4}>
                <Button
                    w={'auto'}
                    fontWeight={'normal'}
                    px={6}
                    colorScheme={'whatsapp'}
                    as={'a'}
                    onClick={() => sendGtagConversion()}
                    href="https://app.robotizap.com/register?type=booking"
                >
                    Testar grátis por 7 dias
                </Button>
            </Flex>

        </Box>
    );
}

function Feature({ isOdd = false, title, text, image }) {
    let _isOdd = useBreakpointValue({base: false, md: isOdd});
    return (
        <Container maxW={'5xl'} py={{base: 10, md: 2}}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} alignItems="center">
                <Stack spacing={4}>
                    <Heading fontSize={"2xl"}>{title}</Heading>
                    <Text color={'gray.500'} fontSize={'lg'}>
                        {text}
                    </Text>
                </Stack>
                <Flex order={_isOdd ? -1 : 1}>
                    <Image
                        h={"50%"}
                        w="auto"
                        rounded={'md'}
                        alt={'feature image'}
                        src={image}
                        objectFit={'cover'}
                    />
                </Flex>
            </SimpleGrid>
        </Container>
    );
}