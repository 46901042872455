import { Box, Container, Heading, Stack } from "@chakra-ui/react";
import Layout from "../Components/Layout";

export default function TermsOfUsage() {
    return (
        <Layout navItems={[
            {
                label: 'Vantagens',
                href: '/#vantagens',
            },
            {
                label: 'Robôs',
                href: '/#robos',
            },
            {
                label: 'Sobre nós',
                href: '/#sobre',
            },
            {
                label: 'Clientes',
                href: '/#clientes',
            }]}>
            <Box bg={"gray.100"} py={5}>
                <Container my={{ base: 0, md: 10 }} mb={{ base: 10, md: 20 }} maxW={'4xl'} id="funcionalidades">
                    <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
                        <Heading fontSize={'3xl'}>Termos de Uso</Heading>
                    </Stack>

                    <Box mt={4}>
                        CONTRATO DE PRESTAÇÃO DE SERVIÇOS, TERMOS DE SERVIÇO E UTILIZAÇÃO DA PLATAFORMA ROBOTIZAP.<br/>
                        CASO O USUÁRIO NÃO CONCORDE COM ESTES TERMOS, ELE NÃO PODERÁ CONTRATAR OS SERVIÇOS COLOCADOS À DISPOSIÇÃO PELA PLATAFORMA ROBOTIZAP.<br/>
                        AO CONCORDAR COM OS TERMOS DAS PRESENTES CONDIÇÕES GERAIS, O USUÁRIO DECLARA TER LIDO INTEGRALMENTE ESTE CONTRATO, TORNANDO CERTO E PERFEITO O ATO DE CONTRATAÇÃO.
                        <br/><br/>

                        <b>1. CONDIÇÕES GERAIS DE USO</b><br/>
                        Estas condições gerais (doravante denominadas “Condições Gerais”) regulamentam a utilização da plataforma de automação e gerenciamento oferecida pela RobotiZap registrada sob o endereço eletrônico https://www.robotizap.com, que é um produto da empresa PK Systems, sob o CNPJ 43.456.936/0001-34.<br/>
                        Uma cópia atualizada destas Condições Gerais estará à disposição através do endereço https://www.robotizap.com/termos-de-uso.<br/>
                        A expressa aceitação sem reservas quanto aos termos de uso atribui a condição de usuário do serviço (doravante, denominado “Usuário”) e declara a aceitação plena e sem reservas do usuário a cada uma das Condições Gerais na versão publicada pela RobotiZap no exato momento em que utilizar o Serviço. Todos os demais avisos, regulamentos e instruções integram e complementam as presentes Condições Gerais.
                        <br/><br/>
                        <b>2. OBJETO</b><br/>
                        Este contrato regulamenta a utilização dos serviços da plataforma ROBOTIZAP.<br/>
                        A ferramenta colocará à disposição um painel administrativo para automatizar e  gerenciar o atendimento através do WhatsApp.<br/>
                        Os dados referentes aos conteúdos ficarão armazenados dentro do sítio da RobotiZap em servidores próprios ou contratados.<br/>
                        O Usuário é o único e exclusivo responsável por manter a confidencialidade de suas Senhas de Acesso e compromete-se a utilizá-las diligentemente, bem como a notificar aos administradores da plataforma, imediatamente, em caso de perda, roubo ou de qualquer uso não autorizado das mesmas.
                        <br/><br/>
                        <b>3. O SERVIÇO</b><br/>
                        O Usuário, após concordar com as presentes Condições Gerais e Termos de Uso terá acesso à plataforma ROBOTIZAP.<br/>
                        Os dados referentes aos conteúdos gerenciados serão armazenados dentro do sítio e dos provedores da ROBOTIZAP ou terceiros contratados.
                        <br/><br/>
                        <b>4. CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SERVIÇO</b><br/>
                        O Usuário reconhece que é essencial para utilização do serviço o preenchimento correto e obediência às regras impostas na ficha de cadastro (assinatura) na ROBOTIZAP, obrigando-se a preenchê-lo com dados verdadeiros e isentando a ROBOTIZAP de qualquer responsabilidade quanto a prejuízos gerados por dados falsos ou imprecisos que sejam fornecidos no referido Cadastro.<br/><br/>
                        O Usuário compromete-se a utilizar o Serviço em conformidade com as presentes Condições Gerais e demais normas e regulamentos da ROBOTIZAP aplicáveis ao serviço, bem como em conformidade com as Leis Brasileiras, a moral e os bons costumes e a ordem pública, utilizando o serviço de forma adequada se abstendo da prática de atos ilícitos, proibidos pela lei e pelas presentes Condições Gerais, lesivos aos direitos e interesses de terceiros, ou que, de qualquer forma, possa danificar, inutilizar, sobrecarregar ou deteriorar o serviço os servidores ou os equipamentos informáticos de outros Usuários ou de outros internautas (“hardware” e “software”) através da prática de “hacking”.<br/><br/>
                        É responsabilidade do usuário a manutenção de cópias locais de seus arquivos, uma vez que o serviço prestado pela ROBOTIZAP não contempla o serviço de backup dos materiais disponíveis em seu sítio, não sendo portanto a ROBOTIZAP responsável por eventuais danos que o Usuário possa vir a sofrer decorrentes da perda, por qualquer motivo, do material inserido ou armazenado nos sítios da ROBOTIZAP.<br/><br/>
                        O serviço não poderá ser utilizado com a finalidade de armazenar, distribuir, transmitir, difundir ou pôr à disposição de terceiros, qualquer tipo de conteúdo que, por si mesmo, ou cuja transmissão:<br/><br/>
                        contravenha, menospreze ou atente contra os direitos fundamentais e liberdades públicas e individuais reconhecidas no ordenamento jurídico brasileiro ou em tratados internacionais; induza, incite ou promova atuações delituosas, difamatórias, infamantes, violentas ou, contrárias à lei, à moral e aos bons costumes ou à ordem pública;<br/><br/>
                        induza, incite ou promova atuações, atitudes ou ideias discriminatórias em razão de sexo, raça, religião, crenças, idade ou condição social; incorpore, ponha à disposição ou permita acessar produtos, elementos, mensagens delituosas, violentas, pornográficas, degradantes ou, em geral, contrárias à lei, à moral e aos bons costumes ou à ordem pública; esteja protegido por quaisquer direitos de propriedade intelectual ou industrial pertencentes a terceiros, sem que o Usuário tenha obtido previamente dos seus titulares a autorização necessária para realizar o uso que efetua ou pretende efetuar; caso em que o Usuário será responsável por quaisquer danos, eximindo desde já a ROBOTIZAP de qualquer responsabilidade ou corresponsabilidade; seja contrários ao direito, à honra, à intimidade pessoal e familiar ou à própria imagem das pessoas; constitua publicidade ilícita, enganosa ou desleal e, em geral, que constitua concorrência desleal; contribua, facilite ou incentive, de qualquer forma, a prática de quaisquer formas de infração aos direitos de propriedade intelectual de qualquer conteúdo disponibilizado na internet.<br/><br/>
                        § 1º Em qualquer das hipóteses acima, a ROBOTIZAP poderá, a seu exclusivo critério, cancelar o registro do Usuário e eliminar o respectivo conteúdo, a qualquer tempo e sem qualquer aviso prévio, nem indenização.<br/><br/>
                        § 2º Qualquer decisão sobre a aplicação, ou não, das presentes Condições Gerais, será tomada segundo exclusivo critério da ROBOTIZAP que poderá ainda, notificar as autoridades policiais e judiciais acerca da existência de conteúdo de Usuários que contenham qualquer classe de material que, a juízo da ROBOTIZAP seja considerado ilegal, fornecendo, ainda, cópias desse conteúdo e demais documentos dos usuários, independentemente de prévio consentimento.<br/><br/>
                        O usuário compromete-se a utilizar a plataforma de forma sã e bem intencionada, sendo assim, a ROBOTIZAP reserva-se o direito de suspender quaisquer contas que façam abuso da plataforma através de quaisquer meios disponíveis (agendamento de publicações, automações etc) para quaisquer finalidades.<br/><br/>
                        O Usuário declara desde já que é o único e exclusivo responsável pelo conteúdo por ele inserido, não tendo a ROBOTIZAP qualquer responsabilidade ou controle sobre este. Em decorrência da presente declaração, o Usuário, desde já obriga-se, caso venha a ser chamado a responder judicialmente pela prática de um ato ilícito ou ilegal em decorrência dos referidos conteúdos, a isentar a ROBOTIZAP de quaisquer responsabilidades, bem como requerer a exclusão da mesma do processo, se for o caso.<br/><br/>
                        A ROBOTIZAP reserva-se o direito de modificar, a qualquer momento, a apresentação e configuração do Serviço, assim como também as presentes Condições Gerais ou quaisquer outros regulamentos e normas que influenciem no Serviço.<br/><br/>
                        Caso ocorram eventos de força maior que não permitam à ROBOTIZAP comunicar com antecedência alterações ou eventuais falhas no serviço, o USUÁRIO neste ato permite que a ROBOTIZAP suspenda a prestação do serviço e rescinda este contrato, ficando desde já acertado que esta informará o ocorrido e sua causa para ciência do USUÁRIO. A ROBOTIZAP não reembolsará pagamentos efetuados pelo USUÁRIO caso haja interrupção temporária ou permanente do serviço, e neste ato o USUÁRIO expressamente concorda com tal política, sem quaisquer ressalvas.<br/><br/>
                        O Usuário deverá manter sempre seu cadastro atualizado junto a ROBOTIZAP, informando pelo menos um endereço de e-mail em funcionamento, para que possa receber, comunicados e avisos. Não somos responsáveis pelos comunicados não recebidos devido às configurações de email do usuário.
                        <br/><br/>
                        <b>5. USO E CUSTÓDIA</b><br/>
                        O usuário ao ter o serviço ativado, informará um “login” e uma senha de acesso ao painel administrativo. Ele deverá alterar a senha periodicamente, guardando consigo a nova senha escolhida.<br/>
                        O Usuário compromete-se a fazer um uso diligente das Senhas de Acesso, assim como a mantê-las em sigilo, conforme demais avisos, regulamentos e instruções fornecidas pela ROBOTIZAP.<br/>
                        O Usuário compromete-se a comunicar a ROBOTIZAP, imediatamente, caso haja perda ou extravio das Senhas de Acesso assim como qualquer risco de acesso às mesmas por um terceiro.<br/>
                        A ROBOTIZAP não se responsabiliza por senhas de acesso extraviadas, perdidas modificadas pelo Usuário, ou por senhas cuja combinação seja considerada muito “fraca”, apta a ser forçada ou adivinhada por qualquer forma.
                        <br/><br/>
                        <b>6. PROPRIEDADES</b><br/>
                        Embora não tenha acesso direto ao banco de dados do programa da ROBOTIZAP, pertence ao USUÁRIO o conteúdo (arquivos e conteúdo inseridos em razão deste contrato), podendo tal conteúdo ser acessado através do painel administrativo.
                        <br/><br/>
                        <b>7. DIREITOS DO USUÁRIO</b><br/>
                        Ao contratar os serviços descritos pela ROBOTIZAP, o Usuário possui os direitos:
                        Utilizar todos os serviços colocados à disposição pela plataforma objeto deste contrato;<br/>
                        O usuário declara neste ato estar ciente que ao cancelar a assinatura, o serviço será imediatamente interrompido e que não haverá ressarcimento de pagamento pelos dias não utilizados.<br/>
                        Hospedar os arquivos a ele referentes, enviar arquivos, utilizar bancos de dados, de acordo com as limitações impostas pelo serviço contratado;<br/>
                        O Usuário se responsabiliza por todo conteúdo colocado na plataforma, incluindo mas não limitado à direitos autorais de terceiros, de documentos inseridos, sejam fotos, vídeos ou textos.<br/>
                        O usuário declara neste ato estar ciente que em casos decorrentes de força maior, manutenção de emergência ou quedas inesperadas de serviço, não será possível notificá-lo com antecedência. A ROBOTIZAP não oferece nenhum tipo de garantia ou reembolso advindo da instabilidade decorrentes de fatos descritos neste inciso.
                        <br/><br/>
                        <b>8. ALTERAÇÃO NO SERVIÇO</b><br/>
                        O usuário tem conhecimento e concorda que o serviço pode mudar de tempos em tempos sem aviso prévio.
                        Mudanças incluem, sem limitação, alterações na taxa e condição de pagamento, patches de segurança, funcionalidades adicionadas ou removidas e outras melhorias ou restrições.<br/>
                        A plataforma não poderá ser responsabilizada pelo usuário ou terceira parte no caso de qualquer modificação, alteração de preço, suspensão ou descontinuação do serviço.<br/>
                        A ROBOTIZAP pode alterar as suas taxas e condições de pagamento para o serviço, sem aviso prévio, respeitando todavia a vigência dos planos já contratados sem cobrança adicional.
                        <br/><br/>
                        <b>9. POLÍTICAS DE COBRANÇA E PAGAMENTO</b><br/>
                        O serviço é prestado para o usuário mediante pagamento de valores previamente estipulados, ou de forma pós-paga, de acordo com o tipo de serviço oferecido e contratado.<br/>
                        O pagamento de faturas da assinatura se dará de forma automática, desde que o cartão de crédito tenha sido utilizado previamente e a cobrança automática ativada.<br/>
                        Caso contrário, o pagamento também poderá ser efetuado por PIX ou Boleto bancário.
                        O usuário concorda desde já manter-se em dia com os pagamentos acordados com a ROBOTIZAP, estando ciente que o atraso no pagamento resultará na interrupção dos serviços.<br/>
                        Para realizar o cancelamento da assinatura, é necessário excluir o robô desejado em até 24 horas antes do fim do ciclo de cobrança, e garante que realizará o pagamento do valor residual referente ao período utilizado no caso de cobrança pós-paga, não se aplicando para robôs no modo pré-pago.
                        <br/><br/>
                        <b>10. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</b><br/>
                        A PK Systems exime-se de quaisquer responsabilidades quanto a danos e prejuízos de qualquer natureza porventura advindos devidos à indisponibilidade ou falta de continuidade do serviço.<br/>
                        A PK Systems exime-se de quaisquer responsabilidades quanto ao uso de automações no perfil. Ao utilizar automações em seus perfis no WhatsApp, o usuário compreende que está colocando em potencial risco suas contas e que as políticas do WhatsApp podem mudar a qualquer momento, resultando em exclusão total, permanente e irrecuperável do perfil.<br/>
                        Os dados do Usuário serão armazenados com todo o sigilo e somente serão manipulados por funcionários da PK Systems. No entanto, a PK Systems não garante a privacidade e a segurança na utilização do Serviço por parte do Usuário, e portanto, não pode garantir que terceiros não autorizados não possam acessar e, eventualmente, interceptar, eliminar, alterar, modificar ou manipular de qualquer modo o conteúdo posto à disposição no Serviço ou interceptar, eliminar, alterar, modificar ou manipular de qualquer modo os Arquivos e comunicações de qualquer classe que o Usuário transmita, armazene ou ponha à disposição de terceiros através do Serviço.<br/>
                        A PK Systems se exime de toda responsabilidade pelos danos e prejuízos de qualquer natureza decorrente do acesso, interceptação, eliminação, alteração, modificação ou manipulação dos arquivos e comunicações armazenados, transmitidos ou postos à disposição por terceiros não autorizados a utilizar o serviço.
                        <br/><br/>
                        <b>11. ATENDIMENTO E SUPORTE TÉCNICO</b><br/>
                        A ROBOTIZAP prestará suporte técnico para seus usuários, obedecendo às seguintes condições e limitações:<br/>
                        A ROBOTIZAP esclarecerá dúvidas e prestará informações simples a usuários através do email email@robotizap.com, ou através do chat de atendimento online disponibilizado dentro da plataforma.
                        <br/><br/>
                        <b>12. FINALIZAÇÃO</b><br/>
                        Os serviços disponíveis na ROBOTIZAP ficarão disponíveis para o usuário pelo prazo definido no plano contratado até impedimento imposto por viés tecnológico (atualizações nos serviços do WhatsApp que impeçam a realização do serviço) ou até o final da existência do serviço, se este for ilimitado.
                    </Box>

                </Container>
            </Box>
        </Layout>
    )
}